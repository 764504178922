/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { makeStyles, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/MenuRounded';
import "@/styles/main.scss"
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TranslateIcon from '@material-ui/icons/TranslateRounded';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useStaticQuery } from 'gatsby';
import { graphql } from 'gatsby';
import { usePageContext } from '@/libraries/hooks/PageContext';
import { navigate } from '@reach/router';
import { getLocalizedPath } from '@/libraries/hooks/helper/path';
import { useEffect } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { useDispatch } from 'react-redux';
import { loggedIn, loggedOut } from '@/state/app';
import { useSelector } from 'react-redux';
import Avatar from '@material-ui/core/Avatar'
const TopSections = [
  { text: 'side_panel.about_us', href: '/about' },
  { text: 'side_panel.contact', href: '/contact' },
  // { text: 'Promote', href: '/promote' },
  // { text: 'Safety Tips', href: '/safety-tips' },
]

const BottomSections = [

  { text: 'side_panel.terms', href: '/terms-of-use' },
  { text: 'side_panel.community_guidelines', href: '/community-guidelines' },
  { text: 'side_panel.advertinsg_policy', href: '/advertising-policy' },
  { text: 'side_panel.privacy_policy', href: '/privacy-policy' },
]

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerRoot: {
    zIndex: 10001,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  bottomButtonContainer: {
    position: 'absolute',
    bottom: '4px',
    width: '100%'
  },
  bottomButton: {
    width: '100%'
  }
}));



const drawerWidth = 240;

const Layout = ({ children }) => {
  const { t, i18n } = useTranslation();
  const { originalPath } = usePageContext();
  const { isLoggedIn, user } = useSelector(state => state.app);

  const { site } = useStaticQuery(
    graphql`
        query {
          site {
            siteMetadata {
              supportedLanguages {
                locale
                label
              }
            }
          }
        }
      `
  )
  const { supportedLanguages } = site.siteMetadata;
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const popOverOpen = Boolean(anchorEl);
  const id = popOverOpen ? 'simple-popover' : undefined;

  // Fetch the saved auth data when first load
  useEffect(() => {
    firebase
      .auth()
      .onAuthStateChanged(user => {
        if (user) {
          firebase
            .firestore()
            .collection('env')
            .doc(`/dev/users/${user.uid}/`)
            .get()
            .then(doc => {
              if (doc.exists) {
                user
                  .getIdToken()
                  .then(token => {
                    dispatch(loggedIn({
                      ...doc.data(),
                      token
                    }));
                  });
                
              }
            });
        }
      })
  }, []);

  return (
    <>
      <div>
        <header>
          <div className="main-header-container">
            <div className="menu-left">
              <div className="title-container">
                <h1 className="site-title" itemProp="name">
                  <a href="/" rel="home" itemProp="url">
                    {t("app.title")}
                  </a>
                </h1>
                <p itemProp="description">
                  {t("app.sub_title")}
                </p>
              </div>
              
              <a className="download-app-images" href="https://qrs.ly/mibtguh" target="_blank">
                <img className="qr-code" src="/images/yitnow_qr_code.png" alt="Download the Yitnow App"/>
                <span>
                  <img src="/images/app_store.jpg" alt="Yitnow on App Store"/>
                  <img src="/images/play_store.jpg" alt="Yitnow on Google Play"/>
                </span>
              </a>
            </div>
            
            <div className="menu-right">
              {isLoggedIn &&
                <Avatar
                  className="profile-pic"
                  src={user.photo}
                  alt={`${user.firstName} ${user.surname}`}
                />}
              {!open && (
                <div className="menu-button">
                  <IconButton onClick={handleDrawerOpen}>
                    <MenuIcon />
                  </IconButton>
                </div>
              )}
            </div>
          </div>
        </header>

        <Drawer
          className={classes.drawer}
          anchor="right"
          open={open}
          onClose={handleDrawerClose}
          classes={{
            paper: classes.drawerPaper,
            root: classes.drawerRoot,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>

          <Divider />
          <List disablePadding={true}>
            <ListItem button>
              <Link to={getLocalizedPath(i18n.language, '/')}>
                <ListItemText primary={t('side_panel.home')} />
              </Link>
            </ListItem>
            <ListItem button>
              {isLoggedIn ?
                <Link to="#">
                  <ListItemText
                    primary={t('side_panel.logout')}
                    onClick={(evt) => {
                      evt.preventDefault();
                      evt.stopPropagation();
                      firebase.auth().signOut();
                      dispatch(loggedOut());
                    }}
                  />
                </Link> :
                <Link to={getLocalizedPath(i18n.language, '/log-in')}>
                  <ListItemText primary={t('side_panel.login')} />
                </Link>
              }
            </ListItem>
            {isLoggedIn && (
              <ListItem button>
                <Link to={getLocalizedPath(i18n.language, '/app/purchase')}>
                  <ListItemText
                    primary={t('side_panel.purchase_coin')}
                  />
                </Link>
              </ListItem>
            )}
            {TopSections.map(({ text, href }, index) => (
              <ListItem button key={text}>
                <Link to={getLocalizedPath(i18n.language, href)}>
                  <ListItemText primary={t(text)} />
                </Link>

              </ListItem>
            ))}
          </List>
          <Divider />
          <List disablePadding={true}>
            {BottomSections.map(({ text, href }, index) => (
              <ListItem button key={text}>
                <Link to={getLocalizedPath(i18n.language, href)}>
                  <ListItemText primary={t(text)} />
                </Link>

              </ListItem>
            ))}
          </List>
          <div className={classes.bottomButtonContainer}>
            <Popover
              id={id}
              open={popOverOpen}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <List component="nav" aria-label="main mailbox folders">
                {supportedLanguages
                  .filter(({ locale }) => locale !== i18n.language)
                  .map(({ locale, label }) =>
                    <ListItem
                      key={locale}
                      button
                      onClick={() => {
                        navigate(getLocalizedPath(locale, originalPath));
                      }}>
                      <Typography

                        className={classes.typography}>{label}
                      </Typography>
                    </ListItem>
                  )}

              </List>
            </Popover>
            {/* <Button
              variant="text"
              startIcon={<TranslateIcon />}
              className={classes.bottomButton}
              onClick={handleClick}
            >
              {supportedLanguages.find(({ locale }) => locale === i18n.language).label}
            </Button> */}
          </div>
        </Drawer>
        <main>{children}</main>
        <footer>
          <p>
            2A Chueng Hing Building, 12P Smithfield, Kennedy Town, Hong Kong S.A.R. | Email: <a href="mailto:contact@yitnow.com">contact@yitnow.com</a>
          </p>
          <p>
            Copyright © 2020 Cordial Radar Limited
          </p>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
